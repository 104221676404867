/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useRouter } from 'next/router';

import { WebviewContext } from './context';

export const usePostMsgContext = () => {
  const context = useContext(WebviewContext);
  const { isPostMessage } = context as any;
  const router = useRouter();

  return {
    isPostMessage,
    clickEvent: (url: string, back?: boolean) => {
      if (!isPostMessage) return undefined;
      return (e: { preventDefault: () => void }) => {
        const x = JSON.stringify({
          url,
          lang: router?.locale ?? 'en',
          ...(back ? { back: true } : { back: false }),
        });
        e.preventDefault();
        //@ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'.
        if (typeof window !== 'undefined' && window.ReactNativeWebView)
          //@ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'.
          window.ReactNativeWebView.postMessage(x);
      };
    },
  };
};

export default usePostMsgContext;
