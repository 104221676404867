/* eslint-disable @typescript-eslint/no-explicit-any */
import { PARENT_URL } from './constant';

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const useNavItems = ({
  webView,
}: any): {
  [key: string]: {
    label: string;
    slug: string;
    href: string;
  }[];
} => {
  const year = new Date().getFullYear();
  return {
    'Top Categories': [
      {
        label: 'Latest',
        slug: '',
        href: '/',
      },
      {
        label: 'EVs',
        slug: 'evs',
        href: '/evs',
      },
      {
        label: 'New Car Launches',
        slug: 'new-launches',
        href: '/new-launches',
      },
      {
        label: 'Used Cars',
        slug: 'used',
        href: '/used',
      },
      {
        label: 'Industry News',
        slug: 'industry',
        href: '/industry',
      },
      {
        label: 'Tips & Guides',
        slug: 'tips',
        href: '/tips',
      },
      {
        label: 'Reviews',
        slug: 'reviews',
        href: '/reviews',
      },
      {
        label: 'Lifestyle',
        slug: 'lifestyle',
        href: '/lifestyle',
      },
    ],
    caricarz: [
      {
        label: 'Used Cars for Sale',
        slug: 'usedCars',
        href: `${PARENT_URL}/used-cars-for-sale`,
      },
      {
        label: 'Recon Cars for Sale',
        slug: 'reconCars',
        href: `${PARENT_URL}/recon-cars-for-sale`,
      },
      {
        label: 'New Cars for Sale',
        slug: 'newCars',
        href: `${PARENT_URL}/new-cars-for-sale`,
      },
      {
        label: 'Search Dealer',
        slug: 'searchDealer',
        href: `${PARENT_URL}/dealer-search`,
      },
      {
        label: 'Certified Selection Search',
        slug: 'certifiedSelectionSearch',
        href: `${PARENT_URL}/certified-selection-search`,
      },
      {
        label: 'New Car Model',
        slug: 'newCarModel',
        href: `${PARENT_URL}/new-cars`,
      },
    ],
    usefulLinks: [
      {
        label: 'Contact Us',
        slug: 'contact',
        href: `${PARENT_URL}/contact-us`,
      },
      {
        label: 'Privacy Policy',
        slug: 'privacy',
        href: webView ? '/webview/privacy-policy' : `/privacy-policy`,
      },
      {
        label: 'Terms Of Use',
        slug: 'term',
        href: webView ? '/webview/term-of-use' : `/terms-of-use`,
      },
      {
        label: 'Sitemap',
        slug: 'sitemap',
        href: webView ? '/webview' : `/browse-by-year/${year}`,
      },
      {
        label: 'Authors',
        slug: 'authors',
        href: webView ? '/webview/authors' : `/authors`,
      },
    ],
  };
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default useNavItems;
