/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import AnimatedBurger from '@apps/components/AnimatedBurger';
import StyledImage from '@apps/components/Image';
import SearchInput from '@apps/components/SearchInput';
import { SocialIcons } from '@apps/config/icons.config';
import useLanguageItems from '@apps/config/language-dropdown.config';
import { useScrollDirection } from '@apps/config/windowscroll.config';
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import { localeConversions } from '@apps/utils/locale-conversion';
import BackIcon from '@stack/assets/icons/back-icon.svg';
import SearchIcon from '@stack/assets/icons/search-primary.svg';
import Logo from '@stack/assets/logo/logo.png';
import SelectDropdown from '@stack/ui/components/Select';
import classNames from 'classnames';
import { useAmp } from 'next/amp';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {
  CategoriesBoxWrapper,
  CollapseHolder,
  CollapseInner,
  DeskLogoInner,
  LanguageIconBox,
  LanguageIconWrapper,
  LanguageTag,
  LanguageWrapper,
  LogoWrapper,
  MbLogoInner,
  MegaMenuItem,
  MenuBurgerWrap,
  MenuItem,
  MenuTitle,
  NavBarSection,
  StyledButton,
  StyledInput,
  StyledSearchBox,
  SubCateBoxWrapper,
  SubMegaMenu,
  SubMenu,
} from './styles';

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const Navbar: React.FC = (props: any) => {
  const { settings, webView, searchbar } = props;
  // ======================= HOOKS
  const router = useRouter();
  const languageOptions = useLanguageItems();
  const pathname = usePathname();
  const { lang, t } = useTranslation('common');
  const defaultLang = languageOptions.find((lo) => lo.value === lang);
  const postCategories = settings?.post_category ?? [];
  const [selectedOption, setSelectedOption] = useState(
    defaultLang?.label ?? 'EN'
  );
  const [collapse, setCollapse] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [type, setCollapseType] = useState('');
  const scrollDirection = useScrollDirection();
  const { clickEvent } = usePostMsgContext();
  const isAmp = useAmp();

  const selectedCategories = router?.query?.category;
  const selectedSubCategories = router?.query?.slug?.[0] ?? '';
  const isDetails = (router?.query?.slug?.[1] && webView) ?? false;
  const newLang = localeConversions(lang);

  const hasSubMenu = postCategories.find((pc: any) => {
    return pc.post_category_slug === selectedCategories;
  })?.post_category_item;

  useEffect(() => {
    const dlang = languageOptions.find((lo) => lo.value === lang);
    setSelectedOption(dlang?.label as string);
  }, []);

  useEffect(() => {
    if (collapse) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'scroll';
    return () => {};
  }, [collapse]);

  useEffect(() => {
    setCollapse(false);
    if (!pathname.includes('/search')) {
      setKeyword('');
    }
  }, [pathname]);

  const handleChange = (value: string) => {
    setSelectedOption(value);
    const currentPath = router?.asPath ?? '/';
    // reset page number when change language
    const updatedPath = currentPath.replace(/([&?]page=\d+)/, '');
    let newUrl = updatedPath;
    if (router.route === '/author/[author]') {
      newUrl = '/';
    }
    router.replace(newUrl, newUrl, { locale: value });
    setCollapse(false);
  };

  const onHandleCollapse = (state: boolean, inType?: string) => {
    // if (inType === 'menu') {
    //   if (type !== inType && type !== '') {
    //     setCollapse(false);
    //     setCollapseType('');
    //     return;
    //   }
    //   if (inType) {
    //     setCollapseType(inType);
    //   }
    //   setCollapse(state);
    //   return;
    // }
    if (inType) {
      setCollapseType(inType);
    }
    if (type !== inType) {
      setCollapse(true);
      return;
    }
    setCollapse(state);
  };

  const handleInputChange = (event: any) => {
    const { target } = event;
    const { value } = target;
    setKeyword(value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (keyword) {
      if (webView) {
        router.push(`/webview/search/${keyword}`);
        return;
      }
      router.push(`/search/${keyword}`);
    }
  };

  const getRedirectUrl = (baseUrl: string) => {
    return webView ? '/webview' + baseUrl : baseUrl;
  };

  // ======================= RENDER FUNCTIONS
  const renderNavItems = () => {
    const isHome =
      router?.route === '/latest' || router?.route === `/webview/latest`;

    return (
      <CategoriesBoxWrapper $show={scrollDirection !== 'down'}>
        <NavBarSection
          style={{
            padding: '5px 20px',
            display: 'flex',
            flexDirection: 'row',
            overflow: 'scroll',
            whiteSpace: 'nowrap',
            margin: '0 auto',
            maxWidth: 1240,
            scrollbarWidth: 'none',
          }}
        >
          <Link
            href={getRedirectUrl(`/latest`)}
            className={classNames('nav-item text-sm-unchanged ', {
              'nav-ani-selected': isHome,
              'nav-ani': !isHome,
            })}
          >
            {t('latest')}
          </Link>
          {postCategories &&
            postCategories.map((navItem: any) => {
              const selected =
                selectedCategories === navItem.post_category_slug;
              return (
                <Link
                  key={navItem.post_category_slug}
                  href={getRedirectUrl(`/${navItem.post_category_slug}`)}
                  className={classNames('nav-item text-sm-unchanged', {
                    'nav-ani-selected': selected,
                    'nav-ani': !selected,
                  })}
                >
                  {navItem?.post_category_name[newLang]}
                </Link>
              );
            })}
        </NavBarSection>
      </CategoriesBoxWrapper>
    );
  };

  const renderSubMenu = () => {
    const isHome =
      router?.route === '/' ||
      router?.route === '/webview' ||
      router?.route === `/webview/latest`;
    if (isHome) return;
    const isAll =
      router?.asPath === `/${selectedCategories}` ||
      router?.asPath === `/webview/${selectedCategories}`;

    if (!hasSubMenu) return;
    return (
      <SubCateBoxWrapper $show={scrollDirection !== 'down'}>
        <div
          style={{
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            overflow: 'scroll',
            whiteSpace: 'nowrap',
            margin: '0 auto',
            maxWidth: 1240,
            scrollbarWidth: 'none',
          }}
        >
          <Link
            href={getRedirectUrl(`/${selectedCategories}`)}
            className={classNames('nav-sub-item text-sm-unchanged ', {
              'nav-ani-selected-3': isAll,
              '': !isAll,
            })}
          >
            {t('all')}
          </Link>
          {postCategories &&
            postCategories
              .find((pc: any) => {
                return pc.post_category_slug === selectedCategories;
              })
              ?.post_category_item?.map((navItem: any) => {
                const selected =
                  selectedSubCategories === navItem.post_category_item_slug;
                return (
                  <Link
                    key={navItem.post_category_slug}
                    href={getRedirectUrl(
                      `/${selectedCategories}/${navItem.post_category_item_slug}`
                    )}
                    className={classNames('nav-sub-item text-sm-unchanged', {
                      'nav-ani-selected-3': selected,
                      '': !selected,
                    })}
                  >
                    {navItem?.post_category_item_name[newLang]}
                  </Link>
                );
              })}
        </div>
      </SubCateBoxWrapper>
    );
  };

  const renderSearch = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBlockEnd: '12px',
          }}
        >
          <h5
            style={{
              marginBlockStart: 0,
              marginBlockEnd: 0,
              fontSize: '1rem',
            }}
          >
            {t('search')}
          </h5>
          {searchbar && (
            <div
              style={{
                padding: '2px 0',
              }}
            >
              <SearchInput
                onHandleCollapse={onHandleCollapse}
                collapseState={collapse}
                value={keyword ?? ''}
                onChange={handleInputChange}
                onHandleSubmit={handleSubmit}
                showClose={true}
              />
            </div>
          )}
        </div>
        <StyledSearchBox onSubmit={handleSubmit} action="/" target="_top">
          <StyledImage
            src={SearchIcon.src}
            alt=""
            width={20}
            height={20}
            imageStyle={{
              height: '20px',
              width: '20px',
              padding: '0 6px',
            }}
          />
          <StyledInput
            type="text"
            name="keyword"
            className=""
            placeholder="Search News"
            value={keyword ?? ''}
            onChange={handleInputChange}
          />
          <StyledButton type="submit">{t('search')}</StyledButton>
        </StyledSearchBox>
      </>
    );
  };

  const renderLanguage = () => {
    const selectedLang = router?.locale;
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBlockEnd: '12px',
          }}
        >
          <MenuTitle
            style={{
              marginBlockStart: 0,
              marginBlockEnd: 0,
            }}
          >
            {t('language')}
          </MenuTitle>
        </div>
        <SubMenu>
          <MenuItem
            className={classNames('', {
              'text-secondary': selectedLang === 'en',
            })}
            onClick={() => {
              handleChange('en');
            }}
          >
            <span>English</span>
          </MenuItem>
          <MenuItem
            className={classNames('', {
              'text-secondary': selectedLang === 'ms',
            })}
            onClick={() => {
              handleChange('ms');
            }}
          >
            <span>Bahasa Malaysia</span>
          </MenuItem>
          <MenuItem
            className={classNames('', {
              'text-secondary': selectedLang === 'zh',
            })}
            onClick={() => {
              handleChange('zh');
            }}
          >
            <span>中文</span>
          </MenuItem>
        </SubMenu>
      </>
    );
  };

  const renderMegaMenu = () => {
    return (
      <div
        className="list"
        style={{
          gap: '1.25rem',
          paddingBlock: 0,
        }}
      >
        {postCategories &&
          postCategories.map((navItem: any) => {
            return (
              <div
                key={navItem.post_category_slug}
                className="list-item list-border"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBlockEnd: '12px',
                    minWidth: '100%',
                  }}
                >
                  <Link
                    href={getRedirectUrl(`/${navItem.post_category_slug}`)}
                    style={{ width: '100%' }}
                  >
                    <MenuTitle style={{ width: '100%' }}>
                      {navItem?.post_category_name[newLang]}
                    </MenuTitle>
                  </Link>
                </div>

                {navItem?.post_category_item && (
                  <SubMegaMenu>
                    {navItem?.post_category_item?.map((pci: any) => {
                      const selected =
                        pathname ===
                        getRedirectUrl(
                          `/${navItem.post_category_slug}/${pci.post_category_item_slug}`
                        );
                      return (
                        <MegaMenuItem key={pci.post_category_slug}>
                          {!selected ? (
                            <Link
                              href={getRedirectUrl(
                                `/${navItem.post_category_slug}/${pci.post_category_item_slug}`
                              )}
                              style={{ width: '100%', display: 'block' }}
                            >
                              {pci?.post_category_item_name[newLang]}
                            </Link>
                          ) : (
                            <span
                              onClick={() => {
                                setCollapse(false);
                              }}
                            >
                              {pci?.post_category_item_name[newLang]}
                            </span>
                          )}
                        </MegaMenuItem>
                      );
                    })}
                  </SubMegaMenu>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const renderMenuItem = () => {
    return (
      <div
        className={classNames('mobile-collapse', {
          'collapse-activated': collapse,
        })}
      >
        <CollapseHolder
          onClick={() => setCollapse(false)}
          style={{
            backgroundImage:
              type === 'menu'
                ? 'linear-gradient(white 40%,transparent 130px)'
                : 'unset',
          }}
        >
          <CollapseInner
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{
              padding:
                type !== 'menu' ? '15px 20px 20px' : '15px 20px 30px 20px',
            }}
          >
            {type === 'search' && renderSearch()}
            {type === 'language' && renderLanguage()}
            {type === 'menu' && renderMegaMenu()}
          </CollapseInner>
        </CollapseHolder>
      </div>
    );
  };

  const renderTopLeftIcon = () => {
    if (isDetails) {
      // if (collapse) {
      //   return (
      //     <MenuBurgerWrap
      //       onClick={() => onHandleCollapse(!collapse, 'menu')}
      //       style={{
      //         height: '14px',
      //         width: '14px',
      //       }}
      //     >
      //       <StyledImage src={CancelIcon.src} alt="" width={14} height={14} />
      //     </MenuBurgerWrap>
      //   );
      // } else {}
      return (
        <MenuBurgerWrap
          onClick={clickEvent('/', true)}
          style={
            isAmp
              ? { height: '16px', width: '16px' }
              : {
                  height: '16px',
                }
          }
        >
          <StyledImage src={BackIcon.src} alt="" width={16} height={16} />
        </MenuBurgerWrap>
      );
    }
    return (
      <MenuBurgerWrap>
        <AnimatedBurger
          toggle={collapse && type === 'menu'}
          onClick={() => onHandleCollapse(!collapse, 'menu')}
        />
      </MenuBurgerWrap>
    );
  };

  // ======================= VIEWS
  return (
    <>
      <div
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: '#FFFFFF',
        }}
      >
        <nav
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              padding: '0 20px',
              borderBottom: '1px solid #ECECEC',
              boxShadow: isDetails
                ? 'rgba(0, 0, 0, 0.08) 0px 4px 16px 0px'
                : 'unset',
            }}
          >
            <div
              style={{
                margin: '0 auto',
                maxWidth: 1240,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                position: 'relative',
              }}
            >
              {renderTopLeftIcon()}
              <LogoWrapper>
                {isDetails ? (
                  <StyledImage
                    src={Logo.src}
                    alt=""
                    width={76}
                    height={24}
                    imageStyle={{
                      objectFit: 'contain',
                      height: '24px',
                    }}
                  />
                ) : (
                  <Link
                    href={getRedirectUrl('/')}
                    style={{
                      display: 'block',
                      maxHeight: '32px',
                    }}
                  >
                    <MbLogoInner
                      style={
                        isAmp
                          ? {
                              height: 'auto',
                              minWidth: '76px',
                            }
                          : {}
                      }
                    >
                      <StyledImage
                        src={Logo.src}
                        alt=""
                        width={76}
                        height={24}
                        imageStyle={{
                          objectFit: 'contain',
                          height: '24px',
                        }}
                      />
                    </MbLogoInner>
                    <DeskLogoInner
                      style={
                        isAmp
                          ? {
                              height: 'auto',
                              minWidth: '76px',
                            }
                          : {}
                      }
                    >
                      <StyledImage
                        src={Logo.src}
                        alt=""
                        width={76}
                        height={24}
                        imageStyle={{
                          objectFit: 'contain',
                          height: '32px',
                        }}
                      />
                    </DeskLogoInner>
                  </Link>
                )}
              </LogoWrapper>
              <div
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                }}
              >
                {!isDetails && (
                  <div
                    style={{
                      padding: '12px 0',
                    }}
                  >
                    <SearchInput
                      onHandleCollapse={onHandleCollapse}
                      collapseState={collapse}
                      value={keyword ?? ''}
                      onChange={handleInputChange}
                      onHandleSubmit={handleSubmit}
                    />
                  </div>
                )}

                <LanguageIconBox>
                  <LanguageIconWrapper
                    onClick={() => {
                      onHandleCollapse(!collapse, 'language');
                    }}
                  >
                    <>
                      <LanguageTag>
                        {languageOptions.find((opt) => {
                          return opt.value === selectedOption;
                        })?.label ?? selectedOption}
                      </LanguageTag>
                      {SocialIcons['language']}
                    </>
                  </LanguageIconWrapper>
                </LanguageIconBox>
                <LanguageWrapper>
                  <SelectDropdown
                    options={languageOptions}
                    value={selectedOption}
                    onChange={handleChange}
                    displayIcon={true}
                    icon={
                      <LanguageWrapper
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      >
                        <LanguageTag>
                          {languageOptions.find((opt) => {
                            return opt.value === selectedOption;
                          })?.label ?? selectedOption}
                        </LanguageTag>
                        {SocialIcons['language']}
                      </LanguageWrapper>
                    }
                  />
                </LanguageWrapper>
              </div>
            </div>
          </div>

          {/* collapsible menu */}
          <div style={{ position: 'relative' }}>
            {collapse && (
              <div
                style={{
                  position: 'absolute',
                  // top: !isDetails ? 0 : 48,
                  top: 0,
                  bottom: 0,
                  minHeight: '100vh',
                  width: '100%',
                  overflow: 'scroll',
                }}
              >
                {renderMenuItem()}
              </div>
            )}
          </div>

          {!isDetails && (
            <div
              style={{
                borderBottom: searchbar
                  ? '1px solid #ECECEC'
                  : '1px solid #ECECEC',
                position: 'relative',
                backgroundColor: 'white',
              }}
            >
              {renderNavItems()}
            </div>
          )}
        </nav>

        {!isDetails && (
          <div
            style={{
              boxShadow: '0px 4px 16px 0px #00000014',
              opacity: collapse ? 0 : 1,
            }}
          >
            {renderSubMenu()}
          </div>
        )}
      </div>
    </>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default Navbar;
