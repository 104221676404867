/* eslint-disable @typescript-eslint/no-explicit-any */
import { FB_PAGE_URL, TWITTER_PAGE_URL, YT_PAGE_URL } from '@apps/config';
import { SocialIcons } from '@apps/config/icons.config';
import useNavItems from '@apps/config/nav.config';
import { localeConversions } from '@apps/utils/locale-conversion';
import Logo from '@stack/assets/logo/logo.png';
import Link from 'next/link';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import StyledImage from '../../components/Image';
import { SocialIconsKeys } from '../../config/icons.config';

import {
  AboutUsWrapper,
  CopyrightInfoWrapper,
  FooterItemWrapper,
  FooterSectionWrapper,
  FooterWrapper,
  IconButton,
  IconsAndImageWrapper,
} from './styles';

/**p
 * ===========================
 * MAIN
 * ===========================
 */
export const Footer: React.FC = (props: any) => {
  const { settings, webView } = props;

  // ======================= HOOKS
  const { lang, t } = useTranslation('common');
  const footerItems = useNavItems({ webView });
  const newLang = localeConversions(lang);

  // ======================= VARIABLES
  const postCategories = settings?.post_category ?? [];

  // ======================= RENDER FUNCTIONS
  // const renderSocialIcon = (icon: string, href: string) => {
  //   return (
  //     <a href={href} target="_blank">
  //       <div
  //         style={{
  //           width: 36,
  //           height: 36,
  //         }}
  //       >
  //         <StyledImage src={icon} alt="" width={1} height={1} />
  //       </div>
  //     </a>
  //   );
  // };

  const renderSocialIconWithHoverEffect = (
    icon: SocialIconsKeys,
    href: string
  ) => {
    return (
      <a href={href} target="_blank" rel="me">
        <IconButton>{SocialIcons[icon]}</IconButton>
      </a>
    );
  };

  const renderSectionTitle = (title: string) => {
    return (
      <div
        style={{
          paddingLeft: 14,
          borderLeft: '7px solid #1E242F',
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {title}
        </p>
      </div>
    );
  };

  const renderFooterSections = () => {
    return (
      <FooterSectionWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            // minWidth: 150,
          }}
        >
          {renderSectionTitle('Top Categories')}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            {postCategories.map((item: any) => {
              return (
                <Link
                  className="text-sm url-ani-2"
                  key={item?.post_category_slug}
                  href={`/${item?.post_category_slug}`}
                  style={{
                    width: 'fit-content',
                  }}
                >
                  {item?.post_category_name[newLang]}
                </Link>
              );
            })}
          </div>
        </div>
        {Object.keys(footerItems).map((key) => {
          if (key === 'Top Categories') return;
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                minWidth: 150,
              }}
            >
              {renderSectionTitle(t('footer.' + key))}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                }}
              >
                {footerItems[key].map((item, index) => {
                  return (
                    <Link
                      className="text-sm url-ani-2"
                      key={index}
                      href={item.href}
                      rel={
                        item?.slug === 'contact' || key === 'caricarz'
                          ? 'nofollow'
                          : ''
                      }
                      target={
                        item?.slug === 'contact' || key === 'caricarz'
                          ? '_blank'
                          : '_self'
                      }
                      style={{
                        width: 'fit-content',
                      }}
                    >
                      {t('footer.' + item.slug)}
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })}
      </FooterSectionWrapper>
    );
  };

  const renderAboutUs = () => {
    return (
      <AboutUsWrapper>
        <h6
          style={{
            fontSize: 20,
            fontWeight: 700,
            margin: 0,
          }}
        >
          {t('footer.aboutUs')}
        </h6>
        <p
          style={{
            lineHeight: '1.5rem',
          }}
        >
          <Trans
            i18nKey="common:footer.aboutUsContent"
            components={[<b key={0} />]}
          />
        </p>
      </AboutUsWrapper>
    );
  };

  const renderCopyrightInfo = () => {
    return (
      <CopyrightInfoWrapper>
        <p
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: '#A8B5C9',
          }}
        >
          © 2024 CariCarz.com. All Rights Reserved.
        </p>
      </CopyrightInfoWrapper>
    );
  };

  // ======================= VIEWS
  return (
    <div
      style={{
        maxWidth: 1240,
        margin: '0 auto',
        padding: '0 20px',
      }}
    >
      <FooterWrapper>
        <IconsAndImageWrapper>
          <div
            style={{
              height: 32,
            }}
          >
            <Link
              href="/"
              style={{
                display: 'block',
                height: 32,
              }}
            >
              <StyledImage
                src={Logo.src}
                alt=""
                width={76}
                height={24}
                imageStyle={{
                  objectFit: 'contain',
                  height: '32px',
                }}
              />
            </Link>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 12,
            }}
          >
            {renderSocialIconWithHoverEffect('facebook-footer', FB_PAGE_URL)}
            {/* {renderSocialIcon(FacebookIcon.src, FB_PAGE_URL)} */}
            {renderSocialIconWithHoverEffect(
              'twitter-footer',
              TWITTER_PAGE_URL
            )}
            {/* {renderSocialIcon(TwitterIcon.src, TWITTER_PAGE_URL)} */}
            {renderSocialIconWithHoverEffect('youtube-footer', YT_PAGE_URL)}
            {/* {renderSocialIcon(YoutubeIcon.src, YT_PAGE_URL)} */}
          </div>
        </IconsAndImageWrapper>
        <FooterItemWrapper>
          {renderAboutUs()}
          {renderFooterSections()}
        </FooterItemWrapper>
        {renderCopyrightInfo()}
      </FooterWrapper>
    </div>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default Footer;
